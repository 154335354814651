import React from 'react'
import {
  Container,
  Box,
  Text,
  SimpleGrid,
  Heading,
  useColorModeValue,
  Link,
  Icon,
  Stack,
  HStack,
} from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactForm from '../components/contact'
import ReactRouterLink from '../components/link'
import { FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa'
import InstaIcon from '../icons/instagram'

const IndexPage = () => {
  const headingColor = useColorModeValue(`headingColor`, `dark.headingColor`)

  return (
    <Layout>
      <Seo
        title="Contact Us | Mud Foods"
        description="Got a question for our pie-oneers? Get in touch! Questions, queries, feedback - we welcome it all! We’ll get back to you as soon as possible."
      />
      <Container pb={20}>
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={10}>
          <Box d={'flex'} alignItems={'center'} pt={20}>
            <Stack spacing={4}>
              <Heading as="h1" color={headingColor}>
                Get In Touch
              </Heading>
              <Text>
                Here at Mud Foods, our team is always happy to hear from you.{' '}
              </Text>

              <Text>
                Questions, queries, feedback - we welcome it all! We’ll get back
                to you as soon as possible.
              </Text>

              <Text>
                Fancy being in touch with us? Why not{' '}
                <Link as={ReactRouterLink} variant="inline" to="/newsletter">
                  Join The Mud Club?
                </Link>
              </Text>

              <Text>We look forward to hearing from you!</Text>

              <Text as="cite">The Mud Team</Text>

              <Text fontSize="sm">
                Pssst - retailers and eateries, check out our{' '}
                <Link
                  as={ReactRouterLink}
                  variant="inline"
                  to="/trade-wholesale"
                >
                  Become a Stockist
                </Link>{' '}
                page for info on how to get our pies out to your customers.
              </Text>
              <HStack>
                <Link ml={1} href="https://facebook.com/mudfoods" isExternal>
                  <Icon
                    w={28}
                    h={28}
                    display="block"
                    aria-label="Find us on facebook"
                    as={FaFacebookSquare}
                    color="#1877f2"
                  />
                </Link>

                <Link ml={2} href="https://instagram.com/mudfoods" isExternal>
                  <InstaIcon
                    w={24}
                    h={24}
                    display="block"
                    aria-label="Find us on Insagram"
                  />
                </Link>
                <Link ml={1} href="https://twitter.com/mudfoods" isExternal>
                  <Icon
                    w={28}
                    h={28}
                    display="block"
                    aria-label="Find us on twitter"
                    as={FaTwitterSquare}
                    color="#1d9bf0"
                  />
                </Link>
              </HStack>
            </Stack>
          </Box>
          <Box
            p={0}
            data-name="product-image-box"
            d={'flex'}
            alignItems={'center'}
            w={'100%'}
          >
            <ContactForm />
          </Box>
        </SimpleGrid>
      </Container>
    </Layout>
  )
}

export default IndexPage
